var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "write-off-table" }, [
    _c(
      "div",
      { staticClass: "list" },
      [
        _c(
          "vxe-table",
          {
            ref: "xTable",
            attrs: {
              border: "",
              "show-overflow": "",
              data: _vm.listPage,
              "edit-config": { trigger: "click", mode: "cell" },
              align: "center",
              rowId: "actDetailCode",
            },
            on: { "edit-closed": _vm.editClosedEvent },
          },
          [
            _c("vxe-table-column", {
              attrs: { field: "sort", width: "60", title: "序号" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var rowIndex = ref.rowIndex
                    return [_vm._v(" " + _vm._s(rowIndex + 1) + " ")]
                  },
                },
              ]),
            }),
            _c("vxe-table-column", {
              attrs: {
                title: "活动编码",
                field: "activityCode",
                "min-width": "100",
              },
            }),
            _c("vxe-table-column", {
              attrs: {
                title: "活动明细编码",
                field: "activityItemCode",
                "min-width": "100",
              },
            }),
            _c("vxe-table-column", {
              attrs: {
                title: "活动明细名称",
                field: "activityItemName",
                "min-width": "140",
              },
            }),
            _c("vxe-table-column", {
              attrs: {
                title: "活动申请执行次数",
                field: "activityNum",
                "min-width": "80",
              },
            }),
            _c("vxe-table-column", {
              attrs: {
                title: "现执行次数",
                field: "applyNum",
                "min-width": "80",
                "edit-render": { name: "$input", props: { type: "number" } },
              },
            }),
            _c("vxe-table-column", {
              attrs: {
                title: "已执行次数",
                field: "executedNum",
                "min-width": "80",
              },
            }),
            _c("vxe-table-column", {
              attrs: {
                title: "剩余可执行次数",
                field: "residueNum",
                "min-width": "80",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }