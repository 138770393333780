var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vxe-table",
        {
          ref: "xTable1",
          attrs: { "row-key": "", "row-id": "id", data: _vm.tableData },
        },
        [
          _c("vxe-table-column", {
            attrs: { title: "日志编码", field: "recordCode" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "操作结果", field: "operationFlag" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "操作类型", field: "operationTypeName" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "操作人", field: "operationCode" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "操作人名称", field: "operationName" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "操作时间", field: "operationTime" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }