var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-table" },
    [
      _c("vxe-toolbar", {
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                _vm.type === "reconsider"
                  ? _c(
                      "vxe-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addTable()
                          },
                        },
                      },
                      [_vm._v("新增复议明细")]
                    )
                  : _vm._e(),
                _vm.type === "close"
                  ? _c(
                      "vxe-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addTable()
                          },
                        },
                      },
                      [_vm._v("新增关闭明细")]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "vxe-table",
        { ref: "fineTable", attrs: { data: _vm.subjectList, height: "300px" } },
        [
          _c("vxe-table-column", {
            attrs: { "min-width": "100", field: "ext1", title: "活动明细名称" },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "120",
              field: "ext65",
              title: "活动明细行状态",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.ext65 === "create"
                      ? _c("span", [_vm._v("待提交")])
                      : _vm._e(),
                    row.ext65 === "approving"
                      ? _c("span", [_vm._v("审批中")])
                      : _vm._e(),
                    row.ext65 === "approved"
                      ? _c("span", [_vm._v("审批通过")])
                      : _vm._e(),
                    row.ext65 === "rejected"
                      ? _c("span", [_vm._v("审批驳回")])
                      : _vm._e(),
                    row.ext65 === "interrupt"
                      ? _c("span", [_vm._v("流程追回")])
                      : _vm._e(),
                    row.ext65 === "closing"
                      ? _c("span", [_vm._v("发起活动关闭")])
                      : _vm._e(),
                    row.ext65 === "closed"
                      ? _c("span", [_vm._v("活动关闭")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "actDetailCode",
              title: "活动明细编码",
            },
          }),
          _c("vxe-table-column", {
            attrs: { "min-width": "100", field: "ext10", title: "是否合规" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.ext10 === "Y" ? _c("span", [_vm._v("是")]) : _vm._e(),
                    row.ext10 === "N" ? _c("span", [_vm._v("否")]) : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "categoriesCode",
              title: "活动大类编码",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "categoriesName",
              title: "活动大类名称",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "fineCode",
              title: "活动细类编码",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "fineName",
              title: "活动细类名称",
            },
          }),
          _c("vxe-table-column", {
            attrs: { "min-width": "130", field: "ext3", title: "公司主体" },
          }),
          _c("vxe-column", {
            attrs: { title: "操作", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    [
                      _c(
                        "span",
                        {
                          staticStyle: { color: "red", cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.deletRow(row)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ],
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }