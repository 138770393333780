import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      progressList: [],
      chooseAct: [],
      formFunctionCode: 'cancel_obtaining_evidence_form',
      buttonText: {
        submit: '提交',
      },
    };
  },

  created() {
  },

  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'attachmentList') { // 选择变更负责人
        v.props = {
          ...v.props,
          accept: '.png,.jpg,.jpeg,',
        };
      }
      return v;
    },
    // 提交
    async submit() {
      const params = this.getFormData();
      if (!params) return false;
      params.actId = this.formConfig.row.actId;
      params.actCode = this.formConfig.row.actCode;
      const url = '/tpm/tpmactcancel/updateSfaExecute';
      const res = await request.post(url, params);
      if (res.success) {
        this.$message({
          type: 'success',
          message: res.message,
        });
        this.$emit('onClose');
        this.$emit('onGetList');
      }
    },
  },
};
