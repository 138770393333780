<template>
  <div>
    <vxe-table row-key row-id="id" ref="xTable1" :data="tableData">
      <vxe-table-column title="日志编码" field="recordCode"></vxe-table-column>
      <vxe-table-column title="操作结果" field="operationFlag"></vxe-table-column>
      <vxe-table-column title="操作类型" field="operationTypeName"></vxe-table-column>
      <vxe-table-column title="操作人" field="operationCode"></vxe-table-column>
      <vxe-table-column title="操作人名称" field="operationName"></vxe-table-column>
      <vxe-table-column title="操作时间" field="operationTime"></vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  name: 'log',
  components: {
  },
  props: {
    formConfig: Object,
  },
  data() {
    return {
      modalConfig: {
        title: '选择字段',
        visible: false,
      },
      logDetail: null,
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取日志列表
    getList() {
      request
        .post('/tpm/tpmActDetailOperationRecord/page', {
          pageNum: 1,
          pageSize: 15,
          onlyKey: this.formConfig.row.actId || this.formConfig.row.id,
          businessCode: this.formConfig.row.actCode,
        })
        .then((res) => {
          if (res.success) {
            this.tableData = res.result.data;
          }
        });
    },
    // 获取列表中包含的数据字典
    getDictCode(val, code) {
      if (JSON.stringify(this.dictCodeList) !== '{}') {
        console.log(code, this.dictCodeList);
        if (this.dictCodeList && this.dictCodeList[code]) {
          const dict = this.dictCodeList[code].find(
            (v) => v.dictCode === `${val}`,
          );
          return dict ? dict.dictValue : val;
        }
        return val;
      }
      return val;
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
</style>
