<template>
  <div class="write-off-table">
    <div class="list">
      <vxe-table border
                 show-overflow
                 ref="xTable"
                 :data="listPage"
                 :edit-config="{trigger: 'click', mode: 'cell'}"
                 @edit-closed="editClosedEvent"
                 align="center"
                 rowId="actDetailCode">
                  <vxe-table-column field="sort" width="60" title="序号">
                  <template v-slot="{ rowIndex }">
                    {{ rowIndex + 1 }}
                  </template>
                </vxe-table-column>
        <vxe-table-column title="活动编码"
                          field="activityCode"
                          min-width="100"></vxe-table-column>
        <vxe-table-column title="活动明细编码"
                          field="activityItemCode"
                          min-width="100"></vxe-table-column>
        <vxe-table-column title="活动明细名称"
                          field="activityItemName"
                          min-width="140"></vxe-table-column>
        <vxe-table-column title="活动申请执行次数"
                          field="activityNum"
                          min-width="80"></vxe-table-column>
        <vxe-table-column title="现执行次数"
                          field="applyNum"
                          min-width="80"
                          :edit-render="{name: '$input',props:{type:'number'}}">
          <!-- <template #default="{ row }">
            <el-input-number v-model="row.applyNum"
                             :controls="false"
                             :min="0"
                             :precision="0"
                             @change="applyNumChange"
                             v-if="!disabled"></el-input-number>
            <span v-else>{{ row.applyNum }}</span>
          </template> -->
        </vxe-table-column>
        <vxe-table-column title="已执行次数"
                          field="executedNum"
                          min-width="80"></vxe-table-column>
        <vxe-table-column title="剩余可执行次数"
                          field="residueNum"
                          min-width="80"></vxe-table-column>
      </vxe-table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'advance_payment',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
  },
  data() {
    return {
      listPage: [],
    };
  },
  computed: {

  },
  created() {
  },
  watch: {
    value() {
      this.listPage = this.value;
    },
  },
  methods: {
    editClosedEvent() {
      this.listPage = this.listPage.map((v, k) => {
        const obj = v;
        obj.applyNum = Number(obj.applyNum);
        obj.residueNum = Number(obj.applyNum) - Number(obj.executedNum);
        return obj;
      });
      // this.$emit('input', this.listPage);
    },
  },
};
</script>

<style lang="less" scoped>
.write-off-table {
  .text-red {
    color: #f56c6c !important;
  }
}
</style>
