import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import ModifyTheNumberOfExecutionsList from './modify_the_number_of_executions.vue';

formCreate.component('ModifyTheNumberOfExecutionsList', ModifyTheNumberOfExecutionsList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      progressList: [],
      chooseAct: [],
      formFunctionCode: 'modify_the_number_of_executions_form',
      buttonText: {
        submit: '提交',
      },
    };
  },

  async created() {
    this.init();
  },

  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      return v;
    },

    formComplete() {
      const actDetailList = this.getRule('actDetailList'); // 活动明细列表
      actDetailList.props = {
        ...actDetailList.props,
      };
    },

    // 提交
    async submit() {
      const params = this.getFormData();
      if (!params) return false;
      const url = '/sfa/activity/updateItemNum';
      params.detailVos = this.chooseAct;
      const res = await request.post(url, params.actDetailList);
      if (res.success) {
        this.$message({
          type: 'success',
          message: res.message,
        });
        this.$emit('onClose');
        this.$emit('onGetList');
      }
    },

    // 初始化数据
    async init() {
      const { row } = this.formConfig;
      const data = await request.post('/sfa/activity/getDetailByActivityCode', { activityCode: row.actCode, pageNum: 1, pageSize: 999 }).then((res) => (res.success ? res.result : []));
      this.chooseAct = [];
      const actDetailList = this.getRule('actDetailList'); // 活动明细列表
      actDetailList.value = data;
      this.setValue(data);
    },
  },
};
