import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import ActDetailList from './actDetailList.vue';

formCreate.component('ActDetailList', ActDetailList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      progressList: [],
      chooseAct: [],
      formFunctionCode: 'reconsideration_form',
      buttonText: {
        submit: '保存',
      },
    };
  },

  async created() {
    this.init();
  },

  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field !== 'workFlowKey' && v.field !== 'title' && v.field !== 'activityFileList' && v.field !== 'approveRemarks') {
        v.props.disabled = true;
      }
      if (v.field === 'workFlowKey') {
        v.options = this.formConfig.progressList;
        v.on = {
          change: (e) => {
            if (e) {
              this.setValue({
                workFlowKey: e,
              });
            } else {
              this.setValue({
                workFlowKey: null,
                workFlowName: null,
              });
            }
          },
          getLabel: (e) => {
            this.setValue({
              workFlowName: e,
            });
          },
        };
      }
      return v;
    },

    formComplete() {
      this.hiddenFields(true, ['ext1']);
      const actDetailList = this.getRule('actDetailList'); // 活动明细列表
      actDetailList.props = {
        ...actDetailList.props,
        actCode: this.formConfig.row.actCode,
        type: 'reconsider',
      };
      // 活动明细列表
      actDetailList.on.change = (e) => {
        if (e) {
          this.chooseAct = e;
        }
      };
    },

    // 提交
    async submit() {
      if (this.chooseAct.length === 0) {
        this.$message.warning('请勾选明细后再复议');
        return;
      }
      const params = this.getFormData();
      if (!params) return false;
      const url = '/tpm/tpmActExt/reconsider';
      params.actType = 'project';
      params.actCode = this.formConfig.row.actCode;
      params.detailVos = this.chooseAct;
      const res = await request.post(url, params);
      if (res.success) {
        this.$message({
          type: 'success',
          message: res.message,
        });
        this.$emit('onClose');
        this.$emit('onGetList');
      }
    },

    // 初始化数据
    async init() {
      const { row } = this.formConfig;
      const data = await request.get('/tpm/tpmActController/query', { id: row.actId || row.id }).then((res) => (res.success ? res.result : []));
      this.chooseAct = [];
      const actDetailList = this.getRule('actDetailList'); // 活动明细列表
      actDetailList.value = [];
      this.setValue(data);
    },
  },
};
